import React, { useContext } from 'react';
import { Field, Form, Formik } from 'formik';
import lodashStartCase from 'lodash/startCase';
import yup from 'yup';

import styles from './IntroducerSignupForm.module.scss';
import IwContext from 'aphrodite-shared/IwContext/IwContext';
import { logErrorToDataLayer } from '../../../../util/googleTracking';
import { Input, Label, Button, Select, FormErrorMessage } from '@iwoca/orion';
import * as questions from './IntroducerSignupFormQuestions';
import { submitIntrodcerSignup } from './IntroducerSignupFormHelper';

const IntroducerSignupInviteForm = ({ introducerUuid }) => {
    const context = useContext(IwContext);

    const handleSubmitForm = async (values, formik) => {
        try {
            await submitIntrodcerSignup(values);
            window.location.href = '/introducer/account';
        } catch (error) {
            context.showApiError('Hmm, something went wrong. Try again?');
            logErrorToDataLayer('Introducer Signup - Form submission error');

            // Prevent freeze in submission state
            formik.setSubmitting(false);
            if (typeof window !== 'undefined') {
                window.Raven.captureException(error);
            }
        }
    };
    return (
        <Formik
            initialValues={{
                [questions.TITLE]: questions.TITLE_OPTIONS[0],
                [questions.FIRST_NAME]: '',
                [questions.LAST_NAME]: '',
                [questions.COMPANY_EMAIL]: '',
                [questions.PHONE_NUMBER]: '',
                [questions.TERMS]: false,
                [questions.UUID]: introducerUuid
            }}
            onSubmit={(values, formik) => handleSubmitForm(values, formik)}
            validationSchema={introducerSignupFormSchema()}
            validateOnChange={false}>
            {(props) => {
                const { isSubmitting, touched, errors, handleSubmit } = props;

                return (
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}>
                        <div className={styles.question}>
                            <Label htmlFor={questions.TITLE}>Title</Label>
                            <Field id={questions.TITLE} name={questions.TITLE}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Select {...field}>
                                            {questions.TITLE_OPTIONS.map(
                                                (option) => {
                                                    return (
                                                        <option value={option}>
                                                            {lodashStartCase(
                                                                option
                                                            )}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.question}>
                            <Label htmlFor={questions.FIRST_NAME}>
                                First name
                            </Label>
                            <Field
                                id={questions.FIRST_NAME}
                                name={questions.FIRST_NAME}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input {...field} />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.question}>
                            <Label htmlFor={questions.LAST_NAME}>
                                Last name
                            </Label>

                            <Field
                                id={questions.LAST_NAME}
                                name={questions.LAST_NAME}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input {...field} />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.question}>
                            <Label htmlFor={questions.COMPANY_EMAIL}>
                                Company email address
                            </Label>
                            <Field
                                id={questions.COMPANY_EMAIL}
                                name={questions.COMPANY_EMAIL}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input {...field} />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.question}>
                            <Label htmlFor={questions.PHONE_NUMBER}>
                                Phone number
                            </Label>
                            <Field
                                id={questions.PHONE_NUMBER}
                                name={questions.PHONE_NUMBER}>
                                {({ field, meta }) => (
                                    <div className={styles.inputArea}>
                                        <Input {...field} />
                                        {meta.touched && meta.error && (
                                            <FormErrorMessage
                                                message={meta.error}
                                            />
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className={styles.submitArea}>
                            <div className={styles.terms}>
                                <Field
                                    id={questions.TERMS}
                                    name={questions.TERMS}>
                                    {({ field, meta }) => (
                                        <Input type="checkbox" {...field} />
                                    )}
                                </Field>

                                <div>
                                    <Label
                                        className={styles.termsLabel}
                                        htmlFor={questions.TERMS}>
                                        I agree to iwoca carrying out due
                                        diligence on the company I represent and
                                        understand that iwoca will contact me
                                        for signature on the iwoca introducer
                                        agreement.
                                    </Label>
                                    {touched[questions.TERMS] &&
                                        errors[questions.TERMS] && (
                                            <FormErrorMessage
                                                message={
                                                    errors[questions.TERMS]
                                                }
                                            />
                                        )}
                                    <div className={styles.termsSubLabel}>
                                        <p>
                                            Your privacy is important to us at
                                            iwoca, and we're committed to
                                            protecting your personal data. We'll
                                            store your personal data securely,
                                            and we'll only get in touch in line
                                            with our{' '}
                                            <a href="https://www.iwoca.co.uk/introducer-privacy-policy/">
                                                {' '}
                                                privacy policy
                                            </a>{' '}
                                            or if you opt in for extra
                                            communication from us.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <Field id={questions.UUID} name={questions.UUID}>
                                {({ field, meta }) => (
                                    <Input type="hidden" {...field} />
                                )}
                            </Field>
                            <Button
                                disabled={isSubmitting}
                                variant="primary"
                                type="submit">
                                {isSubmitting ? 'Submitting' : 'Sign Up'}
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

function introducerSignupFormSchema() {
    return yup.object({
        [questions.TITLE]: yup
            .string()
            .required()
            .oneOf(questions.TITLE_OPTIONS)
            .required('Please enter a title'),
        [questions.FIRST_NAME]: yup
            .string()
            .required()
            .min(0)
            .required('Please enter a first name'),
        [questions.LAST_NAME]: yup
            .string()
            .required()
            .min(0)
            .required('Please enter a last name'),

        [questions.COMPANY_EMAIL]: yup
            .string()
            .email()
            .required()
            .min(0)
            .required('Please enter an email address'),
        [questions.PHONE_NUMBER]: yup
            .string()
            .required()
            .min(0)
            .required('Please enter a phone number'),
        [questions.TERMS]: yup
            .bool()
            .oneOf([true], 'Please accept the terms and conditions')
    });
}

export default IntroducerSignupInviteForm;
